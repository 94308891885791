import React from 'react'
import { Link } from 'gatsby'
import Image from '../../atoms/Image'
import ScrollMonitoringWrapper from '../../atoms/ScrollMonitoringWrapper'
import styles from './portfolio-item.module.scss'

class PortfolioItem extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
      windowHeight : 750
    }
  }

  componentDidMount () {
    this.setState({ windowHeight: window.innerHeight })
  }

  render () {
    const { title = '', featured_media = {}, slug = '', categories = [] } = this.props
    const { windowHeight, animated } = this.state

    return (
      <ScrollMonitoringWrapper offsets={{ top: -(windowHeight / 4) }} isInViewportEvent={() => this.setState({ animated: true })}>
        <div className={`${styles.portfolioItem} ${animated && styles.animated}`}>
          <div className={styles.image}>
            {
              featured_media && featured_media.localFile
                ? <Image {...featured_media} />
                : <img src="/images/gatsby-astronaut.png" alt="Brady Edgar Logo" />
            }

            <i className="far fa-eye" />
          </div>
          <h3><span className={title.length > 35 && styles.smallTitle} dangerouslySetInnerHTML={{ __html: title }} /><strong>{categories && categories[0] && categories[0].name}</strong></h3>
          <Link to={`/portfolio/${slug}`} />
        </div>
      </ScrollMonitoringWrapper>
    )
  }
}

export default PortfolioItem
