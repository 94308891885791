import React from 'react'
import styles from './content-hero.module.scss'

class ContentHero extends React.Component {
  constructor () {
    super()

    this.state = {
      animated: false,
    }
  }

  componentDidMount () {
    setTimeout(() => this.setState({ animated: true }), 250)
  }

  render () {
    const { title = '', content = '', caption = '' } = this.props
    const { animated } = this.state

    return (
      <div className={`${styles.contentHero} ${animated && styles.animated}`}>
        <div className="container">
          <h1><span>{ title }</span></h1>
          <span dangerouslySetInnerHTML={{ __html: content }} />
          { caption && <p>{ caption }</p> }
        </div>
      </div>
    )
  }
}

export default ContentHero
