import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Layout from '../../components/layout'
import ContentHero from '../../components/molecules/ContentHero'
import PortfolioItem from '../../components/molecules/PortfolioItem'
import styles from './portfolio-under-content.module.scss'

export default ({ pageContext }) =>
  <Layout>
    <ContentHero title={pageContext.title} content={pageContext.content} />
    <div className={styles.portfolioUnderContent}>
      <StaticQuery
        query={
          graphql`
            {
              allWordpressWpPortfolio {
                edges {
                  node {
                    title
                    content
                    slug
                    categories {
                      name
                      slug
                    }
                    featured_media {
                      localFile {
                        childImageSharp {
                          sizes(quality: 100) {
                            base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          `
        }
        render={({ allWordpressWpPortfolio = [] }) => (
          <div className="container">
            {
               allWordpressWpPortfolio.edges
                .filter(({ node = {} }) => node.title !== 'DO NOT TOUCH')
                .map(({ node = {} }) => <PortfolioItem key={node.title} {...node} />)
            }
          </div>
        )}
      />
    </div>
  </Layout>
